import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './Context/Context';
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<SnackbarProvider maxSnack={3}>
		<ContextProvider>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</ContextProvider>
	</SnackbarProvider>,
);