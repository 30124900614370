import React  from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useData } from "../Context/Context";

export function PrivateRoute() {
  const { auth } = useData(useData);
  const [isAuth] = auth;

  return(
    isAuth ? <Outlet/> : <Navigate to={'/'} />
  )
}
