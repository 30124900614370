	import React, { useState } from 'react';
	import PropTypes from 'prop-types';
	import MenuIcon from '@mui/icons-material/Menu';
	import MoreVertIcon from '@mui/icons-material/MoreVert';
	import UserMenu from './UserMenu';
	// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
	import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
	import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
	import { useNavigate } from "react-router-dom";
	import ModalConfirm from '../ModalConfirm';
	import API from '../../libs/ServerAPIs'

	import {
	Box,
	AppBar,
	Toolbar,
	IconButton,
	} from '@mui/material';
	import MobileMenu from './MobileMenu';
	import { useData } from '../../Context/Context';

	function AppTopBar({ drawerToggle,openMdlPreferences }) {
	/* VARIABLE DECLERATION
		-------------------------------------------------------------------------------------*/
	const { userData, auth, activeSession, frame} = useData(useData);
	const[,set_visibleFrame]=frame
	const [user_details,setUserDetails] = userData;
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [var_user_menu_elem, set_user_menu_elem] = useState(null);
	const [var_mobile_moreEl, set_mobile_moreEl] = useState(null);
	const isMobileMenuOpen = Boolean(var_mobile_moreEl);
	const navigate = useNavigate();
	const [var_mdl_confirm_open, set_mdl_confirm_open] = useState(false);
	const [var_loading, set_loading] = useState(false);
	const [, setIsActiveSession] = activeSession;
	const [, setIsAuth] = auth;


	const logoutClickHandler = () => {
        set_loading(true);
        API.get("/api/v1/auth/logout").then(res => {
            if (res.message) {
                setIsAuth(false);
                setIsActiveSession(false);
                set_visibleFrame(false);
                setUserDetails(null);
                localStorage.clear();
                sessionStorage.clear();
				set_loading(false)
                navigate("/", { replace: true });
            }
        });
    }


	/* EVENT LISTENERS
		-------------------------------------------------------------------------------------*/

	/* multiselect functionality */

	/* ASYNC FUNCTIONS
		-------------------------------------------------------------------------------------*


	/* EVENT FUNCTIONS
		-------------------------------------------------------------------------------------*/
	function handleUserMenuOpen(event) {
		set_user_menu_elem(event.currentTarget);
		setIsMenuOpen(true)
	}

	function handleMobileMenuOpen(event) {
		set_mobile_moreEl(event.currentTarget);
	}

	function handleMenuClose() {
		set_user_menu_elem(null);
		handleMobileMenuClose();
	}

	function handleMobileMenuClose() {
		set_mobile_moreEl(null);
		setIsMenuOpen(false)
	}

	function openMdlLogout() {
		set_mdl_confirm_open(true);
	}

	function closeMdlLogout() {
		set_mdl_confirm_open(false);
	}

	/* RENDER APP
		-------------------------------------------------------------------------------------*/
	return (
		<>
		<Box sx={{ flexGrow: 1 }}>
		<AppBar position="fixed" sx={{
			width: {md: '100%', lg: 'calc(100% - 240px)'},
			height: '56px',
			backgroundColor: 'primary.main',
			boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 9%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
		}}>

			<Toolbar sx={{ alignItems: 'stretch', px: '0 !important', minHeight: {xs: '56px', sm: '56px', lg: '56px'},}}>
			<IconButton
				size="large"
				edge="start"
				color="inherit"
				aria-label="open drawer"
				sx={{ ml: '0', display: { lg: 'none' } }}
				onClick={drawerToggle}
			>
				<MenuIcon />
			</IconButton>

			<Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', pl: { xs: 0, md: 2 } }}>
				<Box>
				<Box sx={{ color: '#ffffff', fontSize: '16px', fontWeight: '500' }}>{user_details ? `${user_details.firstName} ${user_details.lastName}` : ''}</Box>
				<Box
					sx={{
					color: 'rgba(255,255, 255, 0.75)',
					fontSize: '12px',
					display: 'flex',
					mt: '2px',
					letterSpacing: '0.75px',
					'& > div': { mr: '8px' },
					}}
				>
					{/* <Box>{user_details ? `${user_details.firstName} ${user_details.lastName}` : ''}</Box>
					<Box>{user_details ? user_details.email : ''}</Box> */}
				</Box>
				</Box>
			</Box>

			<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />

				<Box
					component="ul"
					sx={{
						display: 'flex',
						listStyle: 'none',
						p: 0,
						m: 0,
						ml: 'auto',
					}}
				>
					{/* <Box component="li" sx={{ display: { xs: 'none', md: 'block' } }}>
						<IconButton sx={{m: '4px 0', '& svg': {fill: '#ffffff', fontSize: '1.9rem'}}}>
							<SearchOutlinedIcon />
						</IconButton>
					</Box> */}
					<Box component="li" sx={{ display: { xs: 'none', lg: 'flex' } }}>
						<IconButton sx={{m: '8px 0', '& svg': {fill: '#ffffff', fontSize: '2rem'}}} onClick={()=>{navigate('/profile', { replace: true });}}>
							<PersonOutlineOutlinedIcon />
						</IconButton>
					</Box>
					<Box component="li" sx={{ display: { xs: 'none', lg: 'flex' } }}>
						<IconButton onClick={openMdlLogout} color="inherit" sx={{m: '8px 0', '& svg': {fill: '#ffffff', fontSize: '1.7rem'}}} >
							<LogoutOutlinedIcon />
						</IconButton>
					</Box>
					<Box component="li" sx={{ display: { xs: 'flex', lg: 'none' } }}>
						<IconButton onClick={handleMobileMenuOpen} color="inherit">
							<MoreVertIcon />
						</IconButton>
					</Box>
				</Box>

			</Toolbar>

		</AppBar>

		<MobileMenu
			anchorEl={var_mobile_moreEl}
			handleMenuOpen={isMobileMenuOpen}
			handleMobileMenuClose={handleMobileMenuClose}
			handleUserMenuOpen={handleUserMenuOpen}
		/>

		<UserMenu
			anchorEl={var_user_menu_elem}
			handleMenuOpen={isMenuOpen}
			handleMenuClose={handleMenuClose}
			openMdlPreferences={openMdlPreferences}
		/>

		</Box>

		{/***** MODAL: LOGOUT *********************************************************/}
		<ModalConfirm
			title="Logout"
			text="Are you sure you want to logout"
			openModal={var_mdl_confirm_open}
			closeModal={closeMdlLogout}
			type="error"
			loading={var_loading}
			handleConfirm={logoutClickHandler}
		/>

		{/***** END MODAL: LOGOUT  ****************************************************/}
		</>
	);
	}

	AppTopBar.defaultProps = {
		drawerToggle: () => { }
	};

	AppTopBar.propTypes = {
		drawerToggle: PropTypes.func
	};

	export default AppTopBar;
