import React from 'react';
import AppTopBar from '../Components/AppTopBar';
import AppDrawer from '../Components/AppDrawer';
import {
	Box,
	Typography,
	Container
} from '@mui/material';
import { useData } from '../Context/Context';
export function Layout({ children, window }) {
 	/* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
	// const { window } = props;
	const container = window !== undefined ? () => window().document.body : undefined;
    const drawerWidth = 240;
    const { drawerOpen } = useData(useData);
	const [var_drawer_open, set_drawer_open] = drawerOpen;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/



    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/



    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

	function handleDrawerToggle() {
        set_drawer_open(!var_drawer_open);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

  return (
    <>
        <Box
			className="ScpApp"
			sx={{
				pt: '56px',
				pb: '36px',
				boxSizing: 'border-box',
				minHeight: '100vh',
				position: 'relative',
				backgroundColor: '#f5f6f9',
			}}
		>
			<AppTopBar 
				drawerToggle={handleDrawerToggle}
			/>

			<Box sx={{ display: 'flex' }}>

					<AppDrawer
						container={container}
						drawerWidth={drawerWidth}
						open={var_drawer_open}
						drawerToggle={handleDrawerToggle}
					/>
				<Box component="main" sx={{ flexGrow: 1, minWidth: '0' }}>
					{children}
				</Box>

			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: '0',
					left: '0',
					right: '0',
					pl: { lg: '240px' },
					textAlign: 'center',
					height: '36px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Container maxWidth="xl">
					<Box sx={{ position: 'relative' }}>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>&copy; 2022 Sprague Pest Solutions</Typography>
						<Typography
							variant="body2"
							sx={{
								display: { xs: 'none', sm: 'block' },
								position: { sm: 'absolute' },
								right: '0',
								top: '50%',
								transform: { sm: 'translateY(-50%)' },
								color: 'rgba(0,0,0,0.28)',
							}}
						>
							Design &amp; developed by Powersoft19
						</Typography>
					</Box>
				</Container>
			</Box>
		</Box>
    </>
  )
}
