import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DifferenceIcon from '@mui/icons-material/Difference';
import ArchiveIcon from '@mui/icons-material/Archive';

import Logo from '../../logo.svg';
import { Box } from '@mui/material';

import { useData } from '../../Context/Context';
import {
    StyledList,
    StyledLink,
    StyledIcon,
    StyledText,
    StyledListItem,
} from './styles';
import { useNavigate } from 'react-router-dom';

function DrawerList({drawerToggle}) {

    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;

    const history = useNavigate();


    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {

    }, [])


    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/



    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/



    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <StyledList component="nav" sx={{ '& img': { width: '26px' } }} >
            <Box
                    onClick={() => history('/dashboard')}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        zIndex: '1201',
                        position: 'relative',
                        width: { lg: '240px' },
                        py: 5,

                        '& > img': {
                            maxWidth: '180px',
                            width: '100%',
                        },
                    }}
                >
                    <img src={Logo} alt="" />
                </Box>

                    <StyledListItem>
                        <StyledLink component={NavLink} to="/dashboard">
                            <StyledIcon><WebOutlinedIcon /></StyledIcon>
                            <StyledText disableTypography>Dashboard</StyledText>
                        </StyledLink>
                    </StyledListItem>

                    <StyledListItem>
                        <StyledLink component={NavLink} to="/books">
                            <StyledIcon><InsertDriveFileOutlinedIcon /></StyledIcon>
                            <StyledText disableTypography>Current Books</StyledText>
                        </StyledLink>
                    </StyledListItem>
                {user_details?.role_type === 'Admin' &&
                    <StyledListItem>
                        <StyledLink component={NavLink} to="/archived-books">
                            <StyledIcon><ArchiveIcon /></StyledIcon>
                            <StyledText disableTypography>Archived Books</StyledText>
                        </StyledLink>
                    </StyledListItem>
                }
                    <StyledListItem>
                        <StyledLink component={NavLink} to="/resource-center">
                            <StyledIcon><img src="/images/resource-center.svg" alt="" /></StyledIcon>
                            <StyledText disableTypography>Resource Center</StyledText>
                        </StyledLink>
                    </StyledListItem>

                {user_details?.role_type === 'Admin' && <StyledListItem>
                        <StyledLink component={NavLink} to="/quarantine">
                            <StyledIcon><DifferenceIcon /></StyledIcon>
                            <StyledText disableTypography>Quarantine</StyledText>
                        </StyledLink>
                </StyledListItem>}

                {user_details !== undefined && user_details?.role_type === 'Admin' &&
                    <StyledListItem>
                        <StyledLink component={NavLink} to="/administration">
                            <StyledIcon><SettingsOutlinedIcon /></StyledIcon>
                            <StyledText disableTypography>Admin</StyledText>
                        </StyledLink>
                    </StyledListItem>
                }

            </StyledList>
        </>
    )
}

export default DrawerList
