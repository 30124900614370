import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: [
            "Roboto, sans-serif"
        ].join(','),
        h1: {
            fontSize: "36px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f"
        },
        h2: {
            fontSize: "30px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f"
        },
        h3: {
            fontSize: "24px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f"
        },
        h4: {
            fontSize: "20px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f"
        },
        h5: {
            fontSize: "16px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f"
        },
        h6: {
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            fontWeight: '700',
            color: "#1e4f4f",
            letterSpacing: '0.5px'
        },
        body1: {
            fontSize: "14px",
            color: "#39383a"
        },
        body2: {
            fontSize: "12px",
            color: "#39383a"
        },
    },
    

    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    color: '#1e4f4f',
                    fontSize: '14px',
                    whiteSpace: 'nowrap'
                },
                body: {
                    fontSize: '13px'
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '0'
                }
            }
        },
    },
    palette: {
        primary: {
            main: "#1e4f4f",
            light: "#5483b7",
            dark: "#1e4f4f",
        },
        secondary: {
            main: "#dea30f",
            light: "#fab811",
            dark: "#c9940e",
        },
        text: {
            primary: "#39383a",
            secondary: "#999999",
            disabled: "rgba(0,0,0,0.38)"
        }
    },
})