import React from "react";
import {
	Skeleton,
	Container,
	Grid,
	Box,
	Avatar,
	Typography,
	Drawer,
} from "@mui/material";



function ThemeSkeleton({ ListItems = 10 }) {
	/* VARIABLE DECLERATION
		-------------------------------------------------------------------------------------*/
	const skeletonDrawerList = Array(ListItems).fill("");

	/* EVENT LISTENERS
		-------------------------------------------------------------------------------------*/

	/* ASYNC FUNCTIONS
		-------------------------------------------------------------------------------------*/

	/* EVENT FUNCTIONS
		-------------------------------------------------------------------------------------*/

	/* RENDER APP
		-------------------------------------------------------------------------------------*/
	return (
		<Box className="ScpApp skeleton" sx={{
			pt: '56px',
			pb: '36px',
			boxSizing: 'border-box',
			minHeight: '100vh',
			position: 'relative',
			backgroundColor: '#f5f6f9',
		}}>
			<Box sx={{
				position: "fixed",
				zIndex: '1101',
				top: '0',
				left: 'auto',
				right: '0',
				width: {md: '100%', lg: 'calc(100% - 240px)'},
				height: '56px',
				backgroundColor: 'primary.main',
				boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 9%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 4%)',
			}}>
				<Grid sx={{	display: "flex", justifyContent: "space-between", alignItems: "center",	"& .MuiSkeleton-root": { m: "8px 6px",},}}>
					<Grid sx={{ width: "20%" }}>
						<Skeleton animation="wave" variant="rectangular" sx={{ height: "24px", width: "100%", mt: 2 }} />
					</Grid>
					<Grid sx={{	display: "flex", justifyContent: "space-between", alignItems: "center",	"& .MuiSkeleton-root": { m: "8px 6px",},}}>
						<Skeleton animation="wave" variant="circular"><Avatar /></Skeleton>
						<Skeleton animation="wave" variant="circular"><Avatar /></Skeleton>
						<Skeleton animation="wave" variant="circular"><Avatar /></Skeleton>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ display: 'flex' }}>

				<Box
					component="nav"
					sx={{ width: { lg: '240px' }, flexShrink: { lg: 0 } }}
				>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: 'none', lg: 'block' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: '240px',
								backgroundColor: '#ffffff',
								color: 'text.primary',
								border: 'none',
								height: '100%',
							},
						}}
					>
						<Box><Skeleton animation="wave" variant="rectangular" sx={{ height: "52px", width: "80%", m: '42px auto 50px' }} /></Box>
						<Grid sx={{"& .MuiSkeleton-root": {ml: 2},}}>
							{skeletonDrawerList.map((obj, index) => (
								<Box key={index} sx={{p: '8px 0', display: 'flex', alignItems: 'center'}}>
									<Skeleton animation="wave" variant="circular"><Avatar /></Skeleton>
									<Skeleton animation="wave" variant="rectangular" sx={{ height: "24px", width: "60%" }} />
								</Box>
							))}
						</Grid>
					</Drawer>
				</Box>

				<Box component="main" sx={{ flexGrow: 1, minWidth: '0' }}>  </Box>
			</Box>
			<Box
				sx={{
					position: 'absolute',
					bottom: '0',
					left: '0',
					right: '0',
					pl: { lg: '240px' },
					textAlign: 'center',
					height: '36px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Container maxWidth="xl">
					<Box sx={{ position: 'relative' }}>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}><Skeleton animation="wave" variant="rectangular" sx={{ height: "24px", width: "100%" }} /></Typography>
						<Typography	variant="body2"	sx={{ display: { xs: 'none', sm: 'block' },	position: { sm: 'absolute' }, right: '0', top: '50%', transform: { sm: 'translateY(-50%)' }, color: 'rgba(0,0,0,0.28)',}}><Skeleton animation="wave" variant="rectangular" sx={{ height: "24px", width: "100%" }} /></Typography>
					</Box>
				</Container>
			</Box>
		</Box>
	);
	}

	export default ThemeSkeleton;
