
/*
    This class maintains the list of incomplete server requests, adds controller
    for their cancellation when required. The request is registered with the class
*/
class PendingRequestsManager {
    static #requestsList = [];

    static getRequests() {
        return PendingRequestsManager.#requestsList;
    }

    // Add request to request list
    static registerRequest(componentName, apiMethodName, apiUrl) {
        PendingRequestsManager.#requestsList = [
            ...PendingRequestsManager.#requestsList,
            { component: componentName, method: apiMethodName, requestUrl: apiUrl, abortController: null }
        ];
    }

    // Abort/Cancel single request
    static abortRequest(componentName, requestMethod) {
        let index = 0;
        let abortPromise = new Promise((resolve) => {
            for (let request of PendingRequestsManager.#requestsList) {
                if (request['component'] === componentName && request['method'] === requestMethod) {
                    request['abortController']?.abort();
                    PendingRequestsManager.#requestsList.splice(index, 1);
                    break;
                }
                index++;
            }
            resolve(PendingRequestsManager.#requestsList); // provide the remaining list as resolved value
        });
        abortPromise.then(resolvedValue => {
        });
    }

    // Same as abortRequest but accepts list of requests
    static abortRequests(requests = {}) {
        if ('component' in requests) {
            requests['requestMethods'].forEach((apiMethod) => {
                PendingRequestsManager.abortRequest(requests.component, apiMethod);
            });
        }
    }

    // Add controller handle to newly initiated request
    static addAbortControllerToRequest(apiUrl, abortController) {
        for (let request of PendingRequestsManager.#requestsList) {
            if (request['requestUrl'] === apiUrl && request['abortController'] === null) {
                request['abortController'] = abortController;
                break;
            }
        }
    }

    // Delete request once it is finished
    static clearRequest(key, value) {
        if (key === 'method' || key === 'requestUrl') {
            let index = 0;
            for (let request of PendingRequestsManager.#requestsList) {
                if (request[key] === value) {
                    PendingRequestsManager.#requestsList.splice(index, 1);
                    break;
                }
                index++;
            }
        }
    }
}

async function get(path) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const result = await fetchApi(path, requestOptions);
    return result;
}

async function post(path, body = {}, isFormData = false) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
    };
    if (isFormData) {
        delete requestOptions.headers;
        requestOptions.body = body;
    }
    const result = await fetchApi(path, requestOptions);
    return result;
}

async function put(path, body = {}, isFormData = false) {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
    };
    if (isFormData) {
        delete requestOptions.headers;
        requestOptions.body = body;
    }
    const result = await fetchApi(path, requestOptions);
    return result;
}

async function patch(path, body = {}, isFormData = false) {
    const requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
    };
    if (isFormData) {
        delete requestOptions.headers;
        requestOptions.body = body;
    }
    const result = await fetchApi(path, requestOptions);
    return result;
}

async function remove(path, body = {}) {
    const requestOptions = {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
    };
    const result = await fetchApi(path, requestOptions);
    return result;
}

async function fetchApi(path, requestOptions) {

    /* Abort Requests */
    let abortController = new AbortController();
    PendingRequestsManager.addAbortControllerToRequest(path, abortController);
    requestOptions.signal = abortController.signal;
    //console.log('Pending Requests state during fetch ', PendingRequestsManager.getRequests(), ' ', path);
    /* Abort Requests */

    return fetch(path, requestOptions)
        .then(res => {
            if (!res.ok && res.status === 403 && window.location.pathname !== '/') {
                var url_encode = window.location.pathname;
                const params = new URLSearchParams(window.location.search);
                window.location.replace(`/?ref=${encodeURIComponent(url_encode + `?${params.toString()}`)}`)
                return { error: true, message: 'Unauthorized user' };
            }
            if (res.status > 499) {
                return {
                    error: true,
                    statusCode: res.status,
                    message: res.statusText
                }
            }
            // Clear request if completed successfully
            PendingRequestsManager.clearRequest('requestUrl', path);

            const responseType = res.headers.get("Content-Type") || res.headers.get("content-type");

            if (responseType.indexOf('application/json') === -1) {
                return res.blob();
            }
            return res.json();
        })
        .catch(err => {
            // Clear request in case of error too
            PendingRequestsManager.clearRequest('requestUrl', path);
            return {
                error: true,
                message: err
            };
        });
}

const APIServer = { get, post, put, patch, remove, fetchApi, PendingRequestsManager };

export default APIServer;