import React, { useState, createContext, useContext, useEffect } from 'react';
import API from '../libs/ServerAPIs';

const AppContext = createContext();

const ContextProvider = ({ children }) => {

    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const [var_visibleFrame, set_visibleFrame] = useState(false);
    const [var_drawer_open, set_drawer_open] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isStrongPassFlow, setIsStrongPassFlow] = useState(false);
    const [isLoadingData, set_loading_data] = useState(false);
    const [var_refresh_data, set_refresh_data] = useState(false);
    const [user_details, setUserDetails] = useState(null);
    const [app_top_bar_title, set_app_top_bar_title] = useState('');
    const [isActiveSession, setIsActiveSession] = useState(false);
    const [selected_document, setSelectedDocument] = useState({});
    const [selected_parent_tab, setSelectedParentTab] = useState({});

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    useEffect(() => {
        if (user_details) {
        }
    }, [user_details]);


    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/


    async function checkAuthSession() {
        const userInfo = await API.get('api/v1/auth');
        return userInfo;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function getDocumentOrFolderIcon(type, mimeType, size) {
        if (type === "folder")
          return size === "small"
            ? "/images/folder-icon-small.png"
            : "/images/folder-icon.png";
        else {
          if (mimeType === "application/pdf") {
            return size === "small"
              ? "/images/pdf-icon-small.png"
              : "/images/pdf-icon.png";
          } else if (
            mimeType ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            mimeType === "application/msword"
          ) {
            return size === "small"
              ? "/images/doc-icon-small.png"
              : "/images/doc-icon.png";
          } else if (
            mimeType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            mimeType === "application/vnd.ms-excel"
          ) {
            return size === "small"
              ? "/images/excel-icon-small.png"
              : "/images/excel-icon.png";
          } else if (
            mimeType ===
              "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            mimeType === "application/vnd.ms-powerpoint"
          ) {
            return size === "small"
              ? "/images/ppt-icon-small.png"
              : "/images/ppt-icon.png";
          } else {
            return size === "small"
                    ? "/images/file-icon-small.png"
                    : "/images/file-icon.png";
          }
        }
      }


    /* ContextProvider value
    -------------------------------------------------------------------------------------*/
    const data = {
        auth: [isAuth, setIsAuth],
        frame: [var_visibleFrame, set_visibleFrame],
        drawerOpen: [var_drawer_open, set_drawer_open],
        userData: [user_details, setUserDetails],
        refresh_data: [var_refresh_data, set_refresh_data],
        loading_data: [isLoadingData, set_loading_data],
        checkAuth: checkAuthSession,
        getIcon:getDocumentOrFolderIcon,
        appTopBarTitleAsPerPage: [app_top_bar_title, set_app_top_bar_title],
        activeSession: [isActiveSession, setIsActiveSession],
        // committeeId:[var_committeeId, set_committeeId],
        strongPassFlow:[isStrongPassFlow, setIsStrongPassFlow],
        selectedDoc:[selected_document, setSelectedDocument],
        parentTab:[selected_parent_tab, setSelectedParentTab]
    };


    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <AppContext.Provider value={data} >
            {children}
        </AppContext.Provider>
    )
}

const useData = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error("useData can only be used inside AppContext");
    }
    return context;
};


export { ContextProvider, useData }