import React, { lazy } from "react";
import { Routes, Route} from "react-router-dom";
import { PrivateRoute, PublicRoute } from "../helpers";
import { Layout } from "../layout";
const Login = lazy(() => import("../screens/login"));
const Register = lazy(() => import("../screens/register"));
const Dashboard = lazy(() => import("../Pages/Dashboard/index"));
const MainFolders = lazy(()=> import("../Pages/Documents/MainFolders"));
const Book = lazy(()=> import("../Pages/Book"))
const Administration = lazy(() => import("../Pages/Administration"));
const RecoverPassword = lazy(() => import("../screens/RecoverPassword/RecoverPassword"));
const ResetPassword = lazy(() => import("../screens/ResetPassword/ResetPassword"));
const Profile = lazy(() => import("../Pages/Profile/Profile"));
const Quarantine = lazy(() => import("../Pages/Quarantine/Quarantine"));
const ResourceCenter = lazy(() => import("../Pages/ResourceCenter"))
const StrongPassword = lazy(() => import("../Pages/StrongPassword/StrongPassword"));

function AppRoutes({ showSnackbar }) {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoute/>}>
          <Route
            element={<Layout><Dashboard  showSnackbar={showSnackbar}/></Layout>}
            path="/dashboard"
            exact
          />
          <Route path="/books">
          <Route
              element={<Layout><MainFolders showSnackbar={showSnackbar}/></Layout>}
              path="/books"
            />
            <Route
              element={<Layout><Book showSnackbar={showSnackbar}/></Layout>}
              path="/books/:id"
            />
          </Route>
          <Route path="/archived-books">
          <Route
              element={<Layout><MainFolders showSnackbar={showSnackbar} archived={true}/></Layout>}
              path="/archived-books"
            />
            <Route
              element={<Layout><Book showSnackbar={showSnackbar} archived={true}/></Layout>}
              path="/archived-books/:id"
            />
          </Route>
          <Route
            element={<Layout><Profile showSnackbar={showSnackbar}/></Layout>}
            path="/profile"
          />
          <Route
            element={<Layout><Quarantine showSnackbar={showSnackbar}/></Layout>}
            path="/quarantine"
          />
          <Route
            element={<Layout><ResourceCenter showSnackbar={showSnackbar}/></Layout>}
            path="/resource-center"
          />
          <Route
            element={<Layout><Administration showSnackbar={showSnackbar}/></Layout>}
            path="/administration"
            exact
          />
          <Route
            element={<Layout><StrongPassword showSnackbar={showSnackbar}/></Layout>}
            path="/strong-password"
            exact
          />
        </Route>
        <Route element={<PublicRoute />}>
          <Route element={<Login />} path="/" />
          <Route element={<Register />} path="/register" />
          <Route element={<RecoverPassword />} path="/recover-password" />
          <Route element={<ResetPassword />} path="/reset-password" />
        </Route>
      </Routes>
    </>
  );
}
export default AppRoutes;