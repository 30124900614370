import React, {useEffect} from 'react';
import { Drawer, Box } from '@mui/material';
import DrawerList from './DrawerList';

function AppDrawer({container, drawerWidth, open, drawerToggle}) {

    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/


    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {

	}, [])


    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/



    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/



    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClick={drawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#ffffff',
                            border: 'none',
                            height: '100%',
                        },
                    }}
                >
                    <DrawerList/>
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#ffffff',
                            color: 'text.primary',
                            border: 'none',
                            height: '100%',
                        },
                    }}
                    open
                >
                    <DrawerList/>

                </Drawer>
            </Box>
        </>
    )
}

export default AppDrawer
