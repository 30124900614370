import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useData } from '../../../Context/Context'
import API from '../../../libs/ServerAPIs';
import { LoadingButton } from '@mui/lab';

function UserMenu({ anchorEl, handleMenuOpen, handleMenuClose }) {

    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/

    const navigate = useNavigate();
    // const initValues = {
    //     currentPassword: '***************',
    //     oldPassword: '',
    //     newPassword: '',
    //     confirmNewPassword: ''
    // }
    const { userData, frame, auth, activeSession } = useData(useData)
    const [, setUserDetails] = userData;
    const [, setIsAuth] = auth;
    const [, setIsActiveSession] = activeSession;
    const [, set_visibleFrame] = frame;
    const [loading, setLoading] = useState(false);

    /* EVENT LISTENERS -------------------------------------------------------------------------------------*/

    const logoutClickHandler = () => {
        setLoading(true);
        API.get("/api/v1/auth/logout").then(res => {
            if (res.message) {
                setIsAuth(false);
                setIsActiveSession(false);
                set_visibleFrame(false);
                setUserDetails(null);
                localStorage.clear();
                sessionStorage.clear();
                navigate("/", { replace: true });
            }
        });
    }

    /* ASYNC FUNCTIONS -------------------------------------------------------------------------------------*/


    /* EVENT FUNCTIONS -------------------------------------------------------------------------------------*/


    /* RENDER APP -------------------------------------------------------------------------------------*/

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={handleMenuOpen}
                onClose={handleMenuClose}
                sx={{ '& .MuiMenu-list': { p: '0px' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem disableRipple sx={{ py: '8px', mb: '8px', '&:hover': { backgroundColor: 'rgba(0,0,0,0)' } }}>
                    <LoadingButton loading={loading} fullWidth variant="contained" color="primary" onClick={logoutClickHandler} >Log Out</LoadingButton>
                </MenuItem>

            </Menu>
        </>
    )
}

export default UserMenu
