import { styled } from '@mui/material/styles';
import { List, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';

export const StyledList = styled(List)({
    padding: '0'
});

export const StyledListItem = styled(ListItemText)({
    padding: '0'
});

export const StyledLink = styled(ListItemButton)( ({theme})=> ({
    padding: '12px',

    '&:hover': {
        backgroundColor: '#e7e6e8'
    },
    '&.active': {
        backgroundColor: '#e7e6e8',
        '&::after': {
            backgroundColor: theme.palette.secondary.main
        },
    },
    
}));

export const StyledIcon = styled(ListItemIcon)({
    color: 'inherit',
    minWidth: '40px',
    marginLeft: '12px'
});

export const StyledText = styled(ListItemText)({
    fontSize:'14px',
    fontFamily: "Roboto, sans-serif",
    letterSpacing: '0.2px',
    fontWeight: '400',
});
