import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useNavigate } from "react-router-dom";
import ModalConfirm from '../../ModalConfirm';
import API from '../../../libs/ServerAPIs';
import { useData } from '../../../Context/Context';

function MobileMenu({anchorEl, handleMenuOpen, handleMobileMenuClose}) {

    /* VARIABLE DECLERATION --------------------------------------------------------------*/

    const { userData, auth, activeSession, frame} = useData(useData);
    const [var_mdl_confirm_open, set_mdl_confirm_open] = useState(false);
    const [var_loading, set_loading] = useState(false);
    const [,set_visibleFrame]=frame;
    const [,setUserDetails] = userData;
    const [setIsActiveSession] = activeSession;
	const [setIsAuth] = auth;

    const logoutClickHandler = () => {
        set_loading(true);
        API.get("/api/v1/auth/logout").then(res => {
            if (res.message) {
                setIsAuth(false);
                setIsActiveSession(false);
                set_visibleFrame(false);
                setUserDetails(null);
                localStorage.clear();
                sessionStorage.clear();
				set_loading(false)
                navigate("/", { replace: true });
            }
        });
    }

    function openMdlLogout() {
		set_mdl_confirm_open(true);
	}

	function closeMdlLogout() {
		set_mdl_confirm_open(false);
	}

    const navigate = useNavigate();

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={handleMenuOpen}
                onClose={handleMobileMenuClose}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={()=>{navigate('/profile', { replace: true });}}>
                    <IconButton size="small" color="inherit">
                        <PersonOutlineOutlinedIcon />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
                <MenuItem onClick={openMdlLogout}>
                    <IconButton size="small" color="inherit">
                        <LogoutOutlinedIcon />
                    </IconButton>
                    <p>Logout</p>
                </MenuItem>
            </Menu>

            {/***** MODAL: LOGOUT *********************************************************/}
            <ModalConfirm
                title="Logout"
                text="Are you sure you want to logout"
                openModal={var_mdl_confirm_open}
                closeModal={closeMdlLogout}
                type="error"
                loading={var_loading}
                handleConfirm={logoutClickHandler}
            />

            {/***** END MODAL: LOGOUT  ****************************************************/}
        </>
    )
}

export default MobileMenu
