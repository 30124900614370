import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Slide } from "@mui/material";
import AppRoutes from "./Routes";
import { useData } from "./Context/Context";
import "./App.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// import Loader from "./Components/Loader";
import ThemeSkeleton from "./Pages/Components/ThemeSkeleton";

function App(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { auth, activeSession, userData, checkAuth, loading_data } =
    useData(useData);
  const [, set_loading_data] = loading_data;
  const [user_details, setUserDetails] = userData;
  const [, setIsAuth] = auth;
  const [, setIsActiveSession] = activeSession;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function checkSession() {
      if (
        !["/register", "/recover-password", "/reset-password"].includes(
          location.pathname
        )
      ) {
        set_loading_data(true);
        const userInfo = await checkAuth();
        set_loading_data(false);
        if (!userInfo.error) {
          validatePath(location.pathname)
          setIsAuth(true);
          setIsActiveSession(true);
          navigate(location.pathname, { replace: true });
          if (JSON.stringify(userInfo) !== JSON.stringify(user_details)) {
            setUserDetails(userInfo);
            showSnackbar(
              "success",
              `Successfully Login as ${userInfo.firstName} ${userInfo.lastName} `
            );
          }
        } else {
          if (user_details) {
            showSnackbar("error", "Your session has expired.");
            navigate("/", { replace: true });
          }
          setIsAuth(false);
          setUserDetails(null);
          localStorage.clear();
        }
      }
    }
    checkSession();
  }, []);

  function validatePath(path) {
    const splitted_path = path.split("/");
    if (splitted_path &&
        (
            (splitted_path.length === 2)
            ||
            (splitted_path.includes("dashboard"))
            ||
            (splitted_path.includes("documents"))
            ||
            (splitted_path.includes("shared-documents"))
            ||
            (splitted_path.includes("administration"))
        )
    ) {
        return;
    } else if (location.pathname === "/")
        navigate("/dashboard", { replace: true });
    else {
      navigate("/404", { replace: true });
    }
}

  function showSnackbar(variant, message) {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Slide,
    });
  }

  return (
    <div className="App">
      {/* <Suspense fallback={<Loader/>}> */}
      
      <Suspense fallback={<ThemeSkeleton/>}>
        <AppRoutes showSnackbar={showSnackbar} {...props} />
      </Suspense>
    </div>
  );
}

export default App;
